import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../templates/Layout";


export const pageQuery = graphql`
    query About {
        mdx(frontmatter: {title: {eq: "About DBHS"}}) {
            body
            frontmatter {
              title
            }
        }

        allAuthorJson(sort: {fields: order, order: ASC}) {
            edges {
              node {
                friendlyName
                role
              }
            }
          }
    }
`


class About extends React.Component {
    render() {
        const data = this.props.data.mdx;

        return (
            <Layout
                title={data.frontmatter.title}
                location={this.props.location}
            >
                <MDXRenderer>{data.body}</MDXRenderer>
            </Layout>
        )
    }
}
        
export default About